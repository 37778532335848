import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
  position: relative;
  height: ${({ height }) => height};
`;

export const DecoratedImage = ({ image, bubbles, height }) => {
  return (
    <ImageWrapper height={height}>
      <>{image}</>
      {bubbles.map((bubble, idx) => (
        <div key={idx}>{bubble}</div>
      ))}
    </ImageWrapper>
  );
};
