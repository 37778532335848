import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { ButtonSecondary, Container, Flex, Section, Text } from "../../atoms";
import { partiprisColors } from "../../../theme/colors";
import { Link } from "react-router-dom";

const Wrapper = styled(Flex)`
  justify-content: center;
  min-height: 60vh;
  padding-bottom: 60px;
  padding-top: 100px;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
  }
`;

const CTASection = styled(Flex)`
  justify-content: space-between;
  border: 5px solid ${partiprisColors.teal};
  border-radius: 10px;
  width: 70%;
  padding: 40px;
  background-color: #ffffff;

  @media only screen and (${screen.md}) {
    padding: 50px 20px;
    width: 100%;
    display: block;
  }
`;

export const ContactUsCTA = () => {
  return (
    <Wrapper>
      <Container>
        <Flex justifyContent="center">
          <CTASection>
            <Flex alignItems="center">
              <Section>
                <Text type="h1" fontSize="1.6rem" fontWeight="bolder">
                  Do you have a project to discuss?
                </Text>
                <Text type="p">
                  Kindly reach out we are waiting to hear from you.
                </Text>
                <Link to="/contact">
                  <ButtonSecondary>Contact Us</ButtonSecondary>
                </Link>
              </Section>
            </Flex>

            {/* <ImageSection>
              <ImageWrapper>
                <DecoratedImage
                  height="40vh"
                  image={<Image src={heroImg} alt="hero image" />}
                  bubbles={bubbles}
                />
              </ImageWrapper>
            </ImageSection> */}
          </CTASection>
        </Flex>
      </Container>
    </Wrapper>
  );
};
