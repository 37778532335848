const projects = [
  {
    id: 1,
    title: "Project Jasmine",
    description: "A masterpiece",
    images: [
      {
        src: "/projects/jasmine/1.jpg",
        thumbnail: "/projects/jasmine/1.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/2.jpg",
        thumbnail: "/projects/jasmine/2.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/3.jpg",
        thumbnail: "/projects/jasmine/3.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/4.jpg",
        thumbnail: "/projects/jasmine/4.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/5.jpg",
        thumbnail: "/projects/jasmine/5.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/6.jpg",
        thumbnail: "/projects/jasmine/6.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/7.jpg",
        thumbnail: "/projects/jasmine/7.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/8.jpg",
        thumbnail: "/projects/jasmine/8.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/9.jpg",
        thumbnail: "/projects/jasmine/9.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
      {
        src: "/projects/jasmine/10.jpg",
        thumbnail: "/projects/jasmine/10.jpg",
        caption: "Lorem ipsum dolor sit amet",
        width: "1024",
        height: "768",
      },
    ],
  },
];

export default projects;
