import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { Container, Section, Text } from "../../atoms";
import anthony from "../../../assets/images/anthony_oyegwa.jpg";
import elisha from "../../../assets/images/elisha_etefia.jpg";
import sandra from "../../../assets/images/sandra_pride.jpeg";

const Wrapper = styled(Section)`
  padding: 80px 0;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
  }
`;

const Heading = styled(Section)`
  font-weight: bold;
  width: 70%;
  margin-bottom: 20px;

  @media only screen and (${screen.sm}) {
    width: 80%;
  }
`;

const TeamList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;

  @media only screen and (${screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const TeamMember = styled.div``;

const ProjectImage = styled.img`
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
`;

export const OurTeam = () => {
  return (
    <Wrapper>
      <Container>
        <Heading>
          <Text type="h3" fontWeight="bold" fontSize="1.8rem">
            Our Team.
          </Text>
          <Text type="p">
            Meet our team of talented, intelligent, and value oriented
            individuals.
          </Text>
        </Heading>
        <TeamList>
          {[
            { name: "Elisha Etefia", picture: elisha, title: "Consultant" },
            {
              name: "Anthony Oyegwa",
              picture: anthony,
              title: "Operations Manager",
            },
            { name: "Ovuzorie Sandra", picture: sandra, title: "Secretary" },
          ].map((item, idx) => (
            <TeamMember key={idx}>
              <ProjectImage src={item.picture} />
              <Section>
                <Text type="h3" fontSize="1rem" fontWeight="bold">
                  {item.name}
                </Text>
                <Text type="p">{item.title}</Text>
              </Section>
            </TeamMember>
          ))}
        </TeamList>
      </Container>
    </Wrapper>
  );
};
