import React from "react";
import styled from "styled-components";
import heroBg from "../../../assets/images/hero-bg.png";
import { Container, Flex, Section, Text } from "../../atoms";
import dreamHome from "../../../assets/images/dream-home.jpg";
import kitchenRemodeling from "../../../assets/images/kitchen-remodeling.jpg";
import smartHome from "../../../assets/images/smart-home.jpg";
import { screen } from "../../../theme";
import { partiprisColors } from "../../../theme/colors";

const Wrapper = styled.div`
  background-image: url(${heroBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  background-color: #101023;
`;

const ServicesWrapper = styled.div`
  min-height: 80vh;
  padding: 50px 0;
  display: flex;
  align-items: center;
`;

const ServicesItems = styled.div`
  display: grid;
  width: 60%;
  grid-template-columns: auto auto auto;
  gap: 16px;

  overflow-x: auto;

  @media only screen and (${screen.md}) {
    width: 100%;
  }
`;

const ServiceItem = styled.div`
  width: 100%;

  @media only screen and (${screen.md}) {
    width: 180px;
  }
`;

const ServiceImage = styled.div`
  height: 500px;
  border-bottom: 5px solid ${partiprisColors.teal};
  margin-bottom: 20px;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (${screen.md}) {
    height: 250px;
  }
`;

export const Services = () => {
  return (
    <Wrapper>
      <Container>
        <ServicesWrapper>
          <Section>
            <Text
              type="h3"
              fontWeight="bold"
              fontSize="1.8rem"
              textAlign="center"
              margin="0 0 30px 0"
            >
              Our Services
            </Text>

            <Flex justifyContent="center">
              <ServicesItems>
                {[
                  {
                    title: "Dream homes",
                    image: dreamHome,
                    description:
                      "We offer you the opportunity of adding living spaces without changing home footprint. Whether you have a large or small home, room and house additions are invaluable investments.",
                  },
                  {
                    title: "kitchen remodelling",
                    image: kitchenRemodeling,
                    description:
                      "Whether you’re looking for small or grand kitchen remodel ideas to renovate one of the most popular spaces in your home, we offer several directions for you to go in.",
                  },
                  {
                    title: "Bathroom remodelling",
                    image: smartHome,
                    description:
                      "You can give your bathroom design a boost with a detailed planning and our inspirational bathroom remodel ideas. We offer amazing inspirations for master bathrooms, guest bathrooms, and powder rooms.",
                  },
                ].map((item, idx) => (
                  <ServiceItem key={idx}>
                    <ServiceImage backgroundImage={item.image} />
                    <Section>
                      <Text type="h3" fontSize="1rem" fontWeight="bold">
                        {item.title}
                      </Text>
                      <Text type="p">{item.description}</Text>
                    </Section>
                  </ServiceItem>
                ))}
              </ServicesItems>
            </Flex>
          </Section>
        </ServicesWrapper>
      </Container>
    </Wrapper>
  );
};
