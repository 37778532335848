import React from "react";
import {
  Footer,
  NavBar,
  ContactUsCTA,
  AboutHero,
  ProjectApproach,
  ServiceRow,
  OurTeam,
} from "../../organisms";
import { PageTemplate } from "../../templates";
import styled from "styled-components";
import { partiprisColors } from "../../../theme/colors";

const Wrapper = styled.div`
  background-color: ${partiprisColors.lightGrey};
`;

export const AboutPage = () => {
  return (
    <PageTemplate header={<NavBar />} footer={<Footer />}>
      <Wrapper>
        <AboutHero />

        <ProjectApproach />

        <ServiceRow />

        <OurTeam />

        <ContactUsCTA />
      </Wrapper>
    </PageTemplate>
  );
};
