import styled, { css } from "styled-components";
import { screen } from "../../../theme";

const styles = css`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  bottom: ${({ bottom }) => bottom};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;

  @media only screen and (${screen.md}) {
    display: ${({ displaySmallScreen }) => displaySmallScreen};
  }
`;

export const Bubble = styled.div`
  ${styles};
`;

export const PatternedBubble = styled.img`
  ${styles};
`;
