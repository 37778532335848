import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { ButtonSecondary, Container, Flex, Section, Text } from "../../atoms";
import { Footer, NavBar } from "../../organisms";
import { PageTemplate } from "../../templates";
import "photoswipe/dist/photoswipe.css";
import projects from "../../../utils/projectData";

import { Gallery, Item } from "react-photoswipe-gallery";
import { useParams } from "react-router-dom";

const Heading = styled(Section)`
  font-weight: bold;
  width: 30%;

  @media only screen and (${screen.md}) {
    width: 50%;
  }

  @media only screen and (${screen.sm}) {
    width: 100%;
  }
`;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: 20vw; */
  grid-gap: 20px;
  margin-bottom: 150px;

  @media only screen and (${screen.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (${screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const GalleryItem = styled(Item)`
  grid-row: 1 / 2;
  &:nth-child(even) {
    grid-row: 1 / 3;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: center;
  object-position: center;
`;

const HeadingText = styled(Flex)`
  justify-content: space-between;
  margin: 50px 0;

  @media only screen and (${screen.sm}) {
    display: block;
  }
`;

export const ProjectDetailsPage = () => {
  const { id } = useParams();
  const project = projects.find((project) => {
    return project.id === +id;
  });

  return (
    <PageTemplate header={<NavBar />} footer={<Footer />}>
      <Container>
        <HeadingText>
          <Heading>
            <Text type="h3" fontWeight="bold" fontSize="1.8rem">
              {project.title}
            </Text>
            <Text type="p">{project.description}</Text>
          </Heading>

          <Section width="fit-content" height="fit-content">
            <ButtonSecondary>Back</ButtonSecondary>
          </Section>
        </HeadingText>

        <Gallery id="project-gallery">
          <GalleryContainer>
            {project?.images?.map(
              ({ src, thumbnail, caption, width, height }, idx) => {
                return (
                  <GalleryItem
                    id={idx}
                    original={src}
                    thumbnail={thumbnail}
                    width={width}
                    height={height}
                    key={idx}
                  >
                    {({ ref, open }) => (
                      <Image ref={ref} onClick={open} src={src} alt={caption} />
                    )}
                  </GalleryItem>
                );
              }
            )}
          </GalleryContainer>
        </Gallery>
      </Container>
    </PageTemplate>
  );
};
