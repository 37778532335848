import React from "react";
// import { AnimatePresence } from "framer-motion";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import {
  AboutPage,
  ContactPage,
  HomePage,
  ProjectDetailsPage,
  ProjectsPage,
} from "./components";

const App = () => {
  return (
    <Routes>
      <Route exact path={routes.INDEX} element={<HomePage />} />
      <Route exact path={routes.ABOUT} element={<AboutPage />} />
      <Route exact path={routes.CONTACT} element={<ContactPage />} />
      <Route exact path={routes.PROJECTS} element={<ProjectsPage />} />
      <Route
        exact
        path={routes.PROJECT_DETAILS}
        element={<ProjectDetailsPage />}
      />
    </Routes>
  );
};

export default App;
