import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { Container, Flex, Section, Text } from "../../atoms";
import phoneIcon from "../../../assets/svgs/phone.svg";
import locationIcon from "../../../assets/svgs/location.svg";
import map from "../../../assets/images/map.png";
import { partiprisColors } from "../../../theme/colors";
import MapContainer from "../MapContainer";

const Wrapper = styled(Section)`
  min-height: 80vh;
  display: flex;
  align-items: center;
  background-color: ${partiprisColors.lightGrey};

  @media only screen and (${screen.md}) {
    padding: 50px 0;
    display: block;
  }
`;

const ContactWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  width: 40%;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
    width: 100%;
  }
`;

const MapSection = styled.div`
  height: 50vh;
  width: 50%;
  background-image: url(${map});
  background-size: cover;
  background-position: center;
  @media only screen and (${screen.md}) {
    display: 100%;
  }
`;

const Heading = styled(Section)`
  font-weight: bold;
  width: 70%;

  @media only screen and (${screen.sm}) {
    width: 80%;
  }
`;

const ContactItems = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;

  @media only screen and (${screen.sm}) {
    grid-template-columns: auto;
  }
`;

const Icon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
`;

const ContactCard = styled.div`
  background-color: white;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(158, 158, 158, 0.25);
  -moz-box-shadow: 0px 0px 10px 0px rgba(158, 158, 158, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(158, 158, 158, 0.25);

  &:last-child {
    grid-column: span 2;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (${screen.lg}) {
    display: block;
  }
`;

export const ContactMedium = () => {
  return (
    <Wrapper>
      <Container>
        <FlexContainer>
          <ContactWrapper>
            <Heading>
              <Text type="p" margin="0 0 20px 0" fontWeight="bold">
                Want to talk?
              </Text>
              <Text
                type="h3"
                fontWeight="bold"
                fontSize="1.6rem"
                margin="0 0 20px 0"
              >
                Different easy ways to get in touch with us
              </Text>
            </Heading>

            <ContactItems>
              <ContactCard>
                <Icon src={phoneIcon} />
                <Text type="p" margin="0">
                  partiprisltdwr@gmail.com
                </Text>
              </ContactCard>
              <ContactCard>
                <Icon src={phoneIcon} />
                <Text type="p" margin="0">
                  +234 916 9375 817
                </Text>
              </ContactCard>
              {/* <ContactCard>
                <Icon src={phoneIcon} />
                <Text type="p" margin="0">
                  +234 213 213 213
                </Text>
              </ContactCard>
              <ContactCard>
                <Icon src={phoneIcon} />
                <Text type="p" margin="0">
                  +234 213 213 213
                </Text>
              </ContactCard> */}
              <ContactCard>
                <Icon src={locationIcon} />
                <Text type="p" margin="0" width="70%">
                  OOOM plaza 21 Airport Road, Off NNPC link Rd, Warri, Delta
                  State.
                </Text>
              </ContactCard>
            </ContactItems>
          </ContactWrapper>

          <MapSection>
            <MapContainer />
          </MapSection>
        </FlexContainer>
      </Container>
    </Wrapper>
  );
};
