import React from "react";
import styled from "styled-components";
import {
  ButtonPrimary,
  Container,
  Flex,
  Section,
  Text,
  Bubble,
  PatternedBubble,
} from "../../atoms";
import { partiprisColors } from "../../../theme/colors";
import { screen } from "../../../theme";
import heroBg from "../../../assets/images/hero-bg.png";
import { DecoratedImage } from "../DecoratedImage";
import patternedBubble from "../../../assets/svgs/patterned-bubble.svg";
import heroImg from "../../../assets/images/hero-image.jpeg";
import { Link } from "react-router-dom";

const ImageWrapper = styled.div`
  position: relative;
  width: 40%;
  min-height: inherit;

  @media only screen and (${screen.lg}) {
    width: 55%;
  }

  @media only screen and (${screen.md}) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 80vh;
  position: absolute;
  top: 30px;
  border: 10px solid rgba(255, 255, 255, 0.2);
  border-radius: 45% 45% 0 0;
  -webkit-box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);
  -moz-box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);
  box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);
  object-position: center;
  object-fit: cover;

  @media only screen and (${screen.sm}) {
    top: 0;
    height: 60vh;
  }
`;

const HeroWrapper = styled.div`
  background-image: url(${heroBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  background-color: #101023;
`;

const Hero = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  min-height: 70vh;
  flex-wrap: wrap;

  @media only screen and (${screen.md}) {
    display: block;
  }

  @media only screen and (${screen.sm}) {
    min-height: 50vh;
  }
`;

const HeroText = styled(Section)`
  font-weight: bold;
  width: 25%;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
    width: 60%;
  }

  @media only screen and (${screen.sm}) {
    width: 90%;
  }
`;

const bubbles = [
  <PatternedBubble
    left="-70px"
    bottom="30px"
    height="140px"
    width="140px"
    displaySmallScreen="none"
    src={patternedBubble}
  />,
  <Bubble
    top="70px"
    left="50px"
    width="60px"
    height="60px"
    backgroundColor={partiprisColors.purple}
  />,
  <Bubble
    top="260px"
    right="0px"
    width="25px"
    height="25px"
    backgroundColor={partiprisColors.teal}
  />,
];

export const HomeHero = () => {
  return (
    <HeroWrapper>
      <Container>
        <Hero>
          <HeroText className="col-sm-3">
            <Text type="h1" fontSize="2.5rem" fontWeight="bolder">
              We <Text color={partiprisColors.teal}>Construct</Text> your
              Dreams.
            </Text>
            <Text type="p">
              Innovative, Elegant and Sustainable Construction Services
            </Text>
            <Link to="/about">
              <ButtonPrimary>Learn More</ButtonPrimary>
            </Link>
          </HeroText>

          <ImageWrapper>
            <DecoratedImage
              image={<Image src={heroImg} alt="hero image" />}
              bubbles={bubbles}
            />
          </ImageWrapper>
        </Hero>
      </Container>
    </HeroWrapper>
  );
};
