import styled, { css } from "styled-components";
import { partiprisColors } from "../../../theme/colors";

const styles = css`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ border }) => border};
  text-transform: ${({ textTransform }) => textTransform};
  font-size: ${({ fontSize }) => fontSize};
  font-style: ${({ fontStyle }) => fontStyle};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  line-height: ${({ lineHeight }) => lineHeight};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  text-decoration: ${({ textDecoration }) => textDecoration};
  cursor: ${({ cursor }) => cursor};
  white-space: ${({ whitespace }) => whitespace};
`;

export const ButtonPrimary = styled.button`
  ${styles};
  background-color: ${partiprisColors.teal};
  border: 0;
  border-radius: 5px;
  color: ${partiprisColors.black};
  padding: 10px 20px;
`;

export const ButtonSecondary = styled.button`
  ${styles};
  background-color: ${partiprisColors.purple};
  border: 0;
  border-radius: 5px;
  color: ${partiprisColors.white};
  padding: 10px 20px;
`;

export const ButtonTertiary = styled.button`
  background-color: ${partiprisColors.purple};
  border-radius: 5px;
  color: ${partiprisColors.white};
  ${styles};
`;
