import React from "react";
import { ContactMedium, Footer, NavBar, ScheduleCTA } from "../../organisms";
import { PageTemplate } from "../../templates";

export const ContactPage = () => {
  return (
    <PageTemplate header={<NavBar />} footer={<Footer />}>
      <ContactMedium />

      <ScheduleCTA />
    </PageTemplate>
  );
};
