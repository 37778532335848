import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { partiprisColors } from "../../../theme/colors";
import {
  Bubble,
  ButtonSecondary,
  Container,
  Flex,
  Section,
  Text,
} from "../../atoms";
import { DecoratedImage } from "../DecoratedImage";
import mission from "../../../assets/images/mission.png";
import { Link } from "react-router-dom";

const Wrapper = styled(Section)`
  padding: 150px 0;
  background-color: #ffffff;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
  }
`;

const Details = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  width: 40%;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
    width: 100%;
    order: 2;
  }
`;

const ImageSection = styled.div`
  width: 40%;

  @media only screen and (${screen.md}) {
    width: 100%;
    order: 1;
    margin: 20px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 80vh;
  border-radius: 10px;
  -webkit-box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);
  -moz-box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);
  box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);

  @media only screen and (${screen.sm}) {
    height: 60vh;
  }
`;

const Heading = styled(Section)`
  font-weight: bold;
  width: 70%;

  @media only screen and (${screen.sm}) {
    width: 80%;
  }
`;

const ServiceStatement = styled.div``;

const Items = styled(Section)`
  margin-bottom: 20px;
  width: 70%;
`;

const ServiceSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (${screen.md}) {
    flex-direction: column;
  }
`;

const bubbles = [
  <Bubble
    top="70px"
    left="50px"
    width="60px"
    height="60px"
    backgroundColor={partiprisColors.purple}
  />,
  <Bubble
    top="260px"
    right="0px"
    width="25px"
    height="25px"
    backgroundColor={partiprisColors.teal}
  />,
];

const ServiceWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 100px;
  }
`;

export const ServiceRow = () => {
  return (
    <Wrapper>
      <ServiceWrapper>
        <Container>
          <ServiceSection>
            <ImageSection>
              <DecoratedImage
                image={<Image src={mission} alt="mission" />}
                bubbles={bubbles}
              />
            </ImageSection>
            <Details>
              <Heading>
                <Text type="p" fontWeight="bold" color={partiprisColors.teal}>
                  Construction
                </Text>
                <Text
                  type="h3"
                  fontWeight="bold"
                  fontSize="1.3rem"
                  margin="30px 0"
                >
                  We construct all different types of buildings from ground up.
                </Text>
              </Heading>
              <ServiceStatement>
                {[
                  {
                    title: "General Contracting",
                    text: "General contracting is responsible for the day-to-day oversight of a construction site, management of vendors and trades, and the communication of the information to all involved parties throughout the course of a building project. Hire us for your next project.",
                  },
                  {
                    title: "Metal/Steel Buildings",
                    text: "Metal buildings can save you up to 50% over wood construction. We are a metal and steel building specialist. We build durable and cost effective steel structures. No project is too small or large for us",
                  },
                ].map((elem, idx) => (
                  <Items key={idx}>
                    <Text type="h3" fontWeight="bold" fontSize="1rem">
                      {elem.title}
                    </Text>
                    <Text type="p">{elem.text}</Text>
                  </Items>
                ))}

                <Link to="/contact">
                  <ButtonSecondary>Contact Us</ButtonSecondary>
                </Link>
              </ServiceStatement>
            </Details>
          </ServiceSection>
        </Container>
      </ServiceWrapper>

      <ServiceWrapper>
        <Container>
          <ServiceSection>
            <Details>
              <Heading>
                <Text type="p" fontWeight="bold" color={partiprisColors.teal}>
                  Services
                </Text>
                <Text
                  type="h3"
                  fontWeight="bold"
                  fontSize="1.3rem"
                  margin="30px 0"
                >
                  We provide other related services.
                </Text>
              </Heading>

              <ServiceStatement>
                {[
                  {
                    title: "Plumbing Services",
                    text: "We can serve all your plumbing needs - sewer camera examination, setting, repairing and replacement, plumbing remodels, water line repair and replacement, gas line installation and repairs. Industrial plumbing - 24 hours emergency plumbing services available.",
                  },
                  {
                    title: "Electrical Services",
                    text: "We are a licensed electrical contractor in Nigeria. Whether new construction, a remodel, or master mechanical services, we are prepared to help you with your project.",
                  },
                ].map((elem, idx) => (
                  <Items key={idx}>
                    <Text type="h3" fontWeight="bold" fontSize="1rem">
                      {elem.title}
                    </Text>
                    <Text type="p">{elem.text}</Text>
                  </Items>
                ))}

                <Link to="/contact">
                  <ButtonSecondary>Contact Us</ButtonSecondary>
                </Link>
              </ServiceStatement>
            </Details>

            <ImageSection>
              <DecoratedImage
                image={<Image src={mission} alt="mission" />}
                bubbles={bubbles}
              />
            </ImageSection>
          </ServiceSection>
        </Container>
      </ServiceWrapper>

      <ServiceWrapper>
        <Container>
          <ServiceSection>
            <ImageSection>
              <DecoratedImage
                image={<Image src={mission} alt="mission" />}
                bubbles={bubbles}
              />
            </ImageSection>
            <Details>
              <Heading>
                {/* <Text type="p" fontWeight="bold" color={partiprisColors.teal}>
                  Civil
                </Text> */}
                <Text
                  type="h3"
                  fontWeight="bold"
                  fontSize="1.3rem"
                  margin="0 0 16px 0"
                >
                  Civil Construction (Site Development)
                </Text>
              </Heading>

              <ServiceStatement>
                {[
                  {
                    title: "",
                    text: "Partipris Civil Construction has always prided themselves on self-performing most aspects of their own construction projects including the civil portion as well. We now have taken a leap forward in offering our civil construction / site development services to individuals and firms outside the company.",
                  },
                  {
                    title: "",
                    text: "We have built a team along with a fleet of equipment to handle most civil construction projects. Our services include but are not limited to the following;",
                  },
                ].map((elem, idx) => (
                  <Items key={idx}>
                    <Text type="h3" fontWeight="bold" fontSize="1rem">
                      {elem.title}
                    </Text>
                    <Text type="p">{elem.text}</Text>
                  </Items>
                ))}

                <ul>
                  <li>Clearing and grubbing</li>
                  <li>Demolition</li>
                  <li>Erosion control</li>
                  <li>Site grading</li>
                  <li>Aggregate base</li>
                  <li>Storm draining </li>
                  <li>Box culverts</li>
                  <li>Sites utilities</li>
                  <li>Curb and gutter</li>
                  <li>Paving</li>
                </ul>
                <Link to="/contact">
                  <ButtonSecondary>Contact Us</ButtonSecondary>
                </Link>
              </ServiceStatement>
            </Details>
          </ServiceSection>
        </Container>
      </ServiceWrapper>
    </Wrapper>
  );
};
