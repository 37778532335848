import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { Container, Section, Text } from "../../atoms";

const Wrapper = styled(Section)`
  padding: 150px 0 100px 0;
`;

const Heading = styled(Section)`
  font-weight: bold;
  width: 70%;
  margin-bottom: 20px;

  @media only screen and (${screen.sm}) {
    width: 80%;
  }
`;

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media only screen and (${screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const ProjectItem = styled.div``;

const SectionTitle = styled(Text)`
  width: 30%;

  @media only screen and (${screen.md}) {
    width: 90%;
  }
`;

export const ProjectApproach = () => {
  return (
    <Wrapper>
      <Container>
        <Heading>
          <SectionTitle type="h3" fontWeight="bold" fontSize="1.3rem">
            Our approach to projects
          </SectionTitle>
        </Heading>
        <ProjectsList>
          {[
            {
              id: "01",
              title: "Project Schedule",
              text: "Our team of experts work tirelessly to ensure timely project delivery, by setting measurable and achievable milestones.",
            },
            {
              id: "02",
              title: "Scope and quality",
              text: "We constantly deepen our capacity to deliver project of various sizes, while ensuring that safety standards are maintained and quality delivery is guaranteed.",
            },
            {
              id: "03",
              title: "Price/budget",
              text: "Without compromising on quality and standards, we work with our clients to find the best possible ways to deliver on projects.",
            },
          ].map((item) => (
            <ProjectItem key={item}>
              <Section>
                <Text type="h3" fontSize="1rem" fontWeight="bold">
                  {item.id}.
                </Text>
                <Text type="h3" fontSize="1rem" fontWeight="bold">
                  {item.title}
                </Text>
                <Text type="p">{item.text}</Text>
              </Section>
            </ProjectItem>
          ))}
        </ProjectsList>
      </Container>
    </Wrapper>
  );
};
