import PropTypes from "prop-types";

export const PageTemplate = ({ header, footer, children }) => {
  return (
    <>
      {header && <header>{header}</header>}
      <>{children}</>
      {footer && <footer>{footer}</footer>}
    </>
  );
};

PageTemplate.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.any.isRequired,
};
