import React, { useEffect, useState } from "react";
import styled from "styled-components";
import scheduleCTABg from "../../../assets/images/contactbg.png";
import { screen } from "../../../theme";
import { partiprisColors } from "../../../theme/colors";
import { useWindowSize } from "../../../utils/useCustomHooks";
import { ButtonSecondary, Container, Flex, Section, Text } from "../../atoms";

const Wrapper = styled.div`
  min-height: 50vh;
  display: flex;
  align-items: center;
  background-image: url(${scheduleCTABg});
  background-size: cover;
  background-position: center;
  background-color: #01262e;
`;

const Heading = styled(Section)`
  font-weight: bold;
  width: 30%;

  @media only screen and (${screen.md}) {
    width: 100%;
  }
`;

const CTAArea = styled(Flex)`
  background-color: white;
  justify-content: space-between;
  margin: 50px 0;
  align-items: center;
  padding: 50px 20px;
  border-radius: 10px;

  @media only screen and (${screen.md}) {
    display: block;
  }

  @media only screen and (${screen.sm}) {
    padding: 50px 10px;
  }
`;

const ScheduleBtn = styled(ButtonSecondary)`
  border-radius: 0;
`;

const CallBtn = styled(ButtonSecondary)`
  border-radius: 0;
  padding-right: 40px;
  padding-left: 40px;
  background-color: transparent;
  color: black;
  border: 1px solid ${partiprisColors.purple};
`;

const CTABtns = styled(Section)`
  & > a:not(:last-child) {
    margin-right: 20px;
  }
`;

export const ScheduleCTA = () => {
  const { width } = useWindowSize();
  const [url, setURL] = useState(
    "https://web.whatsapp.com/send?phone=2349169375817&text="
  );

  useEffect(() => {
    setWhatsappURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const setWhatsappURL = () => {
    if (width <= parseInt(screen.sm.substring(11, 14))) {
      setURL("https://api.whatsapp.com/send?phone=2349169375817");
    } else {
      setURL("https://web.whatsapp.com/send?phone=2349169375817&text=");
    }
  };

  return (
    <Wrapper>
      <Container>
        <CTAArea>
          <Heading>
            <Text type="h3" fontWeight="bold" fontSize="1.6rem">
              Schedule a free consultation
            </Text>
            <Text type="p">We would love to speak with you.</Text>
          </Heading>

          <CTABtns width="fit-content" height="fit-content">
            <a href="tel: +2349169375817">
              <CallBtn>Call</CallBtn>
            </a>
            <a href={url} target="_blank" rel="noreferrer">
              <ScheduleBtn>Chat</ScheduleBtn>
            </a>
          </CTABtns>
        </CTAArea>
      </Container>
    </Wrapper>
  );
};
