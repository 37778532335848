import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { Container, Flex, Section, Text } from "../../atoms";
import project from "../../../assets/images/project.png";
import { partiprisColors } from "../../../theme/colors";
import starIcon from "../../../assets/svgs/star-icon.svg";

const Wrapper = styled(Section)`
  min-height: 80vh;
  padding-bottom: 80px;
  padding-top: 20px;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
  }
`;

const Caption = styled(Section)`
  width: 60%;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
`;

const TestimonialList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media only screen and (${screen.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (${screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const Testimonial = styled.div`
  border-radius: 5px;
  background-color: white;
  padding: 40px 20px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(158, 158, 158, 0.25);
  -moz-box-shadow: 0px 0px 10px 0px rgba(158, 158, 158, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(158, 158, 158, 0.25);
`;

const ClientImage = styled.div`
  height: 60px;
  width: 60px;
  border: 4px solid ${partiprisColors.teal};
  border-radius: 50%;
  margin-bottom: 20px;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Testimonials = () => {
  return (
    <Wrapper>
      <Container>
        <TestimonialList>
          <Flex direction="column" justifyContent="center">
            <Caption>
              <Icon src={starIcon} />
              <Text type="h3" fontSize="1.5rem" fontWeight="bold">
                What our clients have to say about us.
              </Text>
              <Text type="p" margin="10px 0">
                Here are some of the values we uphold as we create solutions for
                our customers
              </Text>
            </Caption>
          </Flex>
          {[
            {
              name: "Mabiaku Tuoyo",
              designation: "E.D Officer",
              testimony:
                "I once had a project that was taking unnecessary amount of money, I told the CEO of Partipris civil engineering the problem, the moment they took charge, I saw the difference in terms of saving money and job satisfaction. I will not hesitate to use them again!",
            },
            {
              name: "Robert Okhuarobo",
              designation: "C.E.O., Robethan Global Resources ltd.",
              testimony:
                "Partipris Civil is one you really want to work with as they give you the very best of quality when it comes to design and we never regret working with them as they gives a top notch finishing and very professional when it comes to civil engineering. We really enjoy all work done my this team and my company is always looking forward working with them for many years to come as you cannot best the quality is one of the very best in the world.",
            },
            {
              name: "Vino Oyegwa",
              designation: "Manager",
              testimony: "Great job by this company. I love your work.",
            },
          ].map((item) => (
            <Testimonial key={item}>
              <Flex alignItems="center">
                <ClientImage backgroundImage={project} />
                <Flex direction="column" padding="0 10px">
                  <Text
                    type="h3"
                    fontSize="1rem"
                    fontWeight="bold"
                    margin="0 0 5px 0"
                  >
                    {item.name}
                  </Text>
                  <Text type="p" fontSize="0.7rem" fontWeight="bold">
                    {item.designation}
                  </Text>
                </Flex>
              </Flex>
              <Section>
                <Text type="p" margin="10px 0">
                  {item.testimony}
                </Text>
              </Section>
            </Testimonial>
          ))}
        </TestimonialList>
      </Container>
    </Wrapper>
  );
};
