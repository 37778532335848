import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import logoImg from "../../../assets/svgs/logo.svg";
import { Container, Flex, Icon, Text } from "../../atoms";
import { partiprisColors } from "../../../theme/colors";
import { screen } from "../../../theme";

const MobileState = css`
  @media only screen and (${screen.sm}) {
    justify-content: center;
    padding: 20px 0;
  }
`;

const Wrapper = styled.nav`
  background-color: black;
  color: white;
  padding: 50px 0 30px 0;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

const LinkItems = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 0.8rem;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:hover {
    color: ${partiprisColors.teal};
  }
`;

const SocialMediaSection = styled(Flex)`
  justify-content: flex-end;

  ${MobileState};
`;

const QuickLinks = styled(Flex)`
  justify-content: center;
  ${MobileState};
`;

const BrandIdentity = styled(Flex)`
  ${MobileState};
`;

export const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <div className="row d-flex align-items-center">
          <BrandIdentity className="col-12 col-sm-2">
            <div className="d-flex align-items-center">
              <Logo src={logoImg} alt="logo" />
              <Text
                textTransform="uppercase"
                lineHeight="1rem"
                fontWeight="bold"
                fontSize="0.7rem"
              >
                Partipris Civil Construction
              </Text>
            </div>
          </BrandIdentity>

          <QuickLinks className="col-12 col-sm-8">
            <LinkItems to="/about">About Us</LinkItems>
            <LinkItems to="/projects">Projects</LinkItems>
            <LinkItems to="/contact">Contact Us</LinkItems>
          </QuickLinks>

          <SocialMediaSection className="col-12 col-sm-2">
            {/* <LinkItems to="#">
              <Icon type="facebook" fill="white" width="10px" />
            </LinkItems> */}
            <LinkItems to="https://youtube.com/channel/UC0WLB1wZaTJHGtxqDlAEfRg">
              <Icon type="youtube" fill="white" width="16px" />
            </LinkItems>
            <LinkItems to="https://www.instagram.com/partipris_civil_construction/">
              <Icon type="instagram" fill="white" width="16px" />
            </LinkItems>
          </SocialMediaSection>
        </div>
      </Container>

      <div className="d-flex justify-content-center pt-5">
        <Text type="p" fontSize="0.7rem">
          Copyright &copy; 2020. All rights reserved.
        </Text>
      </div>
    </Wrapper>
  );
};
