import React from "react";
import styled from "styled-components";
import {
  ContactUsCTA,
  Footer,
  NavBar,
  Projects,
  Testimonials,
} from "../../organisms";
import { PageTemplate } from "../../templates";
import background from "../../../assets/images/projects.png";
import { Flex, Section, Text } from "../../atoms";
import { screen } from "../../../theme";
import { partiprisColors } from "../../../theme/colors";

const Hero = styled(Flex)`
  align-items: center;
  flex-direction: column;
  min-height: 70vh;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 160px;
  padding: 40px 0;
  border-bottom: 10px solid ${partiprisColors.teal};
  position: relative;
  margin-bottom: 160px;

  @media only screen and (${screen.sm}) {
    min-height: 75vh;
    background-position-y: 160px;
    background-position-x: center;
  }
`;

const Heading = styled(Section)`
  text-align: center;
  font-weight: bold;
  width: 30%;
  margin-bottom: 20px;

  @media only screen and (${screen.md}) {
    width: 50%;
  }

  @media only screen and (${screen.sm}) {
    width: 80%;
  }
`;

const ProjectStats = styled(Flex)`
  background-color: ${partiprisColors.teal};
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  border: 10px solid ${partiprisColors.teal};
  border-radius: 10px;
  position: absolute;
  bottom: -125px;

  @media only screen and (${screen.sm}) {
    width: 95%;
    bottom: -90px;
  }
`;

const ProjectStat = styled(Flex)`
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  text-align: center;
  padding: 40px 0;
  border-radius: 10px;

  @media only screen and (${screen.sm}) {
    padding: 20px 0;
  }
`;

const ExtraStatText = styled(Text)`
  width: 80%;
  text-align: center;

  @media only screen and (${screen.md}) {
    display: none;
  }
`;

export const ProjectsPage = () => {
  return (
    <PageTemplate header={<NavBar />} footer={<Footer />}>
      <Hero>
        <Heading>
          <Text type="p" fontWeight="bold" color={partiprisColors.teal}>
            Our Projects
          </Text>
          <Text type="h3" fontWeight="bold" fontSize="1.8rem">
            Completed projects?
          </Text>
          <Text type="p" fontWeight="bold">
            A glimpse into the world of our expertise
          </Text>
        </Heading>

        <ProjectStats>
          {[
            {
              percentage: "95%",
              sellingPoint: "Client Referal",
              text: "98% of our clients referr us to their contact",
            },
            {
              percentage: "100%",
              sellingPoint: "Project Completion",
              text: "All projects trusted to us have been successfully completed.",
            },
            {
              percentage: "98%",
              sellingPoint: "Client Satisfaction",
              text: "Our clients have been nothing but satisfied with our services",
            },
          ].map((elem) => (
            <ProjectStat key={elem}>
              <Text type="h3" fontWeight="bold" fontSize="1.8rem">
                {elem.percentage}
              </Text>
              <Text type="p" fontWeight="bold">
                {elem.sellingPoint}
              </Text>
              <ExtraStatText type="p">{elem.text}</ExtraStatText>
            </ProjectStat>
          ))}
        </ProjectStats>
      </Hero>

      <Projects learnMore={false} />

      <Testimonials />

      <ContactUsCTA />
    </PageTemplate>
  );
};
