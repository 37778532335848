import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { partiprisColors } from "../../../theme/colors";
import { Bubble, Container, Flex, Section, Text } from "../../atoms";
import { DecoratedImage } from "../DecoratedImage";
import mission from "../../../assets/images/core-values.jpg";
import flowerIcon from "../../../assets/svgs/flower-icon.svg";
import sustainabilityIcon from "../../../assets/svgs/sustainability-icon.svg";
import lightIcon from "../../../assets/svgs/light-icon.svg";
import starIcon from "../../../assets/svgs/star-icon.svg";

const Wrapper = styled(Section)`
  min-height: 80vh;
  padding-bottom: 80px;
  padding-top: 20px;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
  }
`;

const MissionStatement = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  width: 40%;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
    width: 100%;
  }
`;

const ImageSection = styled.div`
  width: 40%;

  @media only screen and (${screen.md}) {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 80vh;
  border-radius: 45% 45% 0 0;
  -webkit-box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);
  -moz-box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);
  box-shadow: 0px 31px 28px -12px rgba(189, 187, 189, 1);
  object-position: center;
  object-fit: cover;

  @media only screen and (${screen.sm}) {
    height: 60vh;
  }
`;

const Heading = styled(Section)`
  font-weight: bold;
  width: 70%;

  @media only screen and (${screen.sm}) {
    width: 80%;
  }
`;

const MissionItems = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
`;

const bubbles = [
  <Bubble
    top="70px"
    left="50px"
    width="60px"
    height="60px"
    backgroundColor={partiprisColors.purple}
  />,
  <Bubble
    top="260px"
    right="0px"
    width="25px"
    height="25px"
    backgroundColor={partiprisColors.teal}
  />,
];

export const OurMission = () => {
  return (
    <Wrapper>
      <Container>
        <Flex justifyContent="space-between">
          <ImageSection>
            <DecoratedImage
              image={<Image src={mission} alt="mission" />}
              bubbles={bubbles}
            />
          </ImageSection>
          <MissionStatement>
            <Heading>
              <Text type="h3" fontWeight="bold" fontSize="1.8rem">
                Our Core Values
              </Text>
              <Text type="p">
                Here are some values we uphold as we create solutions for our
                clients.
              </Text>
            </Heading>

            <MissionItems>
              <Section>
                <Icon src={flowerIcon} />
                <Text type="h3" fontWeight="bold" fontSize="1rem">
                  Quality
                </Text>
                <Text type="p">
                  No matter the scope of the project, we maintain quality and
                  excellence service deivery from start to finish. We do not
                  compromise on safety and industrial standards, as we maintain
                  global acceptable best practice from end to end.
                </Text>
              </Section>

              <Section>
                <Icon src={sustainabilityIcon} />
                <Text type="h3" fontWeight="bold" fontSize="1rem">
                  Integrity
                </Text>
                <Text type="p">
                  As our practice widens, we uphold high ethical standards and a
                  strong professional culture. We continously hold ourselves to
                  higher standards of integrity in project delivery from start
                  to finish.
                </Text>
              </Section>

              <Section>
                <Icon src={starIcon} />
                <Text type="h3" fontWeight="bold" fontSize="1rem">
                  Flexibility
                </Text>
                <Text type="p">
                  We go the extra mile to satisfy our clients. We pay attention
                  to details from early design stages and continously do so till
                  every detail is carefully executed. we see the client as part
                  of our team, as we deploy our expertise to bring dreams to
                  reality.
                </Text>
              </Section>

              <Section>
                <Icon src={lightIcon} />
                <Text type="h3" fontWeight="bold" fontSize="1rem">
                  Commitment
                </Text>
                <Text type="p">
                  We believe that the best results are achieved by the early and
                  commited collaboration of all project stakeholders from the
                  design and construction process through to the entire life
                  cycle of the project.
                </Text>
              </Section>
            </MissionItems>
          </MissionStatement>
        </Flex>
      </Container>
    </Wrapper>
  );
};
