import React from "react";
import {
  Footer,
  HomeHero,
  NavBar,
  Projects,
  Testimonials,
  OurMission,
  Services,
  ContactUsCTA,
} from "../../organisms";
import { PageTemplate } from "../../templates";
import styled from "styled-components";
import backgroundText from "../../../assets/images/background-text.png";
import { partiprisColors } from "../../../theme/colors";

const Wrapper = styled.div`
  background-color: ${partiprisColors.lightGrey};
`;

const BackgroundTextSection = styled.div`
  height: 20vh;
  background-image: url(${backgroundText});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const HomePage = () => {
  return (
    <PageTemplate header={<NavBar />} footer={<Footer />}>
      <Wrapper>
        <HomeHero />

        <BackgroundTextSection />

        <OurMission />

        <Projects learnMore={true} />

        <Testimonials />

        <Services />

        <ContactUsCTA />
      </Wrapper>
    </PageTemplate>
  );
};
