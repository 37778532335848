import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logoImg from "../../../assets/svgs/logo.svg";
import hamburgerIcon from "../../../assets/images/hamburger.png";
import { screen } from "../../../theme";
import { partiprisColors } from "../../../theme/colors";
import { ButtonSecondary, Container, Flex } from "../../atoms";

const Wrapper = styled.nav`
  background-color: black;
  color: white;
  padding: 10px 0;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

const ContactBtn = styled(ButtonSecondary)`
  border-radius: 3px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #ffffff;
  padding: 8px 16px;
  &:hover {
    color: ${partiprisColors.teal};
  }
`;

const MenuLinks = styled(Link)`
  text-decoration: none;
  color: white;

  &:hover {
    color: ${partiprisColors.teal};
  }

  &:not(:last-child) {
    margin-right: 36px;
  }
`;

const MenuLinkSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (${screen.md}) {
    width: 100%;
    padding: 30px 0;
    display: ${({ display }) => display};
  }
`;

const HamburgerIcon = styled.img`
  display: none;
  width: 25px;
  height: 25px;
  @media only screen and (${screen.md}) {
    display: block;
  }
`;

const NavFlex = styled(Flex)`
  justify-content: space-between;
  align-items: center;

  @media only screen and (${screen.md}) {
    flex-wrap: wrap;
  }
`;

export const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Wrapper>
      <Container>
        <NavFlex>
          <MenuLinks to="/">
            <Logo src={logoImg} alt="logo" />
          </MenuLinks>

          <HamburgerIcon
            onClick={() => setShowMenu(!showMenu)}
            src={hamburgerIcon}
            alt="menu"
          />

          <MenuLinkSection display={showMenu ? "flex" : "none"}>
            <MenuLinks to="/about">About Us</MenuLinks>
            <MenuLinks to="/projects">Projects</MenuLinks>
            <MenuLinks to="/contact">
              <ContactBtn>Contact Us</ContactBtn>
            </MenuLinks>
          </MenuLinkSection>
        </NavFlex>
      </Container>
    </Wrapper>
  );
};
