import React from "react";
import styled from "styled-components";
import { screen } from "../../../theme";
import { ButtonSecondary, Container, Section, Text } from "../../atoms";
import { Link } from "react-router-dom";
import { partiprisColors } from "../../../theme/colors";
import projects from "../../../utils/projectData";

const Wrapper = styled(Section)`
  min-height: 80vh;
  padding-bottom: 80px;
  padding-top: 20px;

  @media only screen and (${screen.md}) {
    padding: 50px 0;
  }
`;

const Heading = styled(Section)`
  font-weight: bold;
  width: 70%;
  margin-bottom: 20px;

  @media only screen and (${screen.sm}) {
    width: 80%;
  }
`;

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media only screen and (${screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const ProjectItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;

  &:hover {
    color: ${partiprisColors.teal};
  }
`;

const ProjectImage = styled.div`
  height: 350px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (${screen.md}) {
    height: 250px;
  }
`;

const LearnMore = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (${screen.md}) {
    justify-content: flex-start;
  }
`;

export const Projects = ({ learnMore }) => {
  return (
    <Wrapper>
      <Container>
        <Heading>
          <Text type="h3" fontWeight="bold" fontSize="1.8rem">
            Projects.
          </Text>
        </Heading>
        <ProjectsList>
          {projects.map((item) => (
            <ProjectItem to={`/project-details/${item.id}`} key={item}>
              <ProjectImage backgroundImage={item.images[0].src} />
              <Section>
                <Text type="h3" fontSize="1rem" fontWeight="bold">
                  {item.title}
                </Text>
                <Text type="p">{item.description}</Text>
              </Section>
            </ProjectItem>
          ))}
        </ProjectsList>
        {learnMore && (
          <LearnMore>
            <Link to="/projects">
              <ButtonSecondary>Learn More</ButtonSecondary>
            </Link>
          </LearnMore>
        )}
      </Container>
    </Wrapper>
  );
};
